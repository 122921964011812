// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10883(5028b50d6e)-C14/11/2024-10:23:58-B14/11/2024-17:53:00' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10883(5028b50d6e)-C14/11/2024-10:23:58-B14/11/2024-17:53:00",
  branch: "master",
  latestTag: "6.1",
  revCount: "10883",
  shortHash: "5028b50d6e",
  longHash: "5028b50d6e56d18fda093db59ca5c1e3e5075ab2",
  dateCommit: "14/11/2024-10:23:58",
  dateBuild: "14/11/2024-17:53:00",
  buildType: "Ansible",
  } ;
